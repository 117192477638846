<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="gpath_career_univ_scholarshipAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchgpath_career_univ_scholarshipWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
              <b-col v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="col-7 col-sm-4 col-md-3 col-lg-3 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
              </b-col>
              <b-col class="col-5 col-sm-3 col-md-2 col-lg-3 mb-2">
                <VueJsonToCsv :json-data="gpath_career_univ_scholarshipObjList" csv-title="gpath_career_univ_scholarship_list" v-if="gpath_career_univ_scholarshipObjList && gpath_career_univ_scholarshipObjList.length">
                  <b-btn variant="primary" class="Download_button">CSV</b-btn>
                </VueJsonToCsv>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="gpath_career_univ_scholarshipObjList && gpath_career_univ_scholarshipObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpath_career_univ_scholarshipObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="gpath_career_univ_scholarshipEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showGPathCareerUnivScholarshipDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGPathCareerUnivScholarshipAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl"
    >
      <GPathCareerUnivScholarshipAdd :propOpenedInModal="true" @emitCloseGPathCareerUnivScholarshipAddModal="closeGPathCareerUnivScholarshipAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_career_univ_scholarshipAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathCareerUnivScholarshipAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelGPathCareerUnivScholarshipEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl"
    >
      <GPathCareerUnivScholarshipEdit :propOpenedInModal="true" :propGPathCareerUnivScholarshipObj="gpath_career_univ_scholarshipEditObj" @emitCloseGPathCareerUnivScholarshipEditModal="closeGPathCareerUnivScholarshipEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_career_univ_scholarshipEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathCareerUnivScholarshipEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelGPathCareerUnivScholarshipDelete" title="Delete" ok-title="Delete" cancel-title="Cancel" size="xl">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGPathCareerUnivScholarshipDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="gpath_career_univ_scholarshipDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { GPathCareerUnivScholarships } from "../../../FackApi/api/GPathCareerUnivScholarship.js"
import GPathCareerUnivScholarshipAdd from "./GPathCareerUnivScholarshipAdd"
import GPathCareerUnivScholarshipEdit from "./GPathCareerUnivScholarshipEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"

export default {
  name: "GPathCareerUnivScholarshipList",
  components: {
    GPathCareerUnivScholarshipAdd,
    GPathCareerUnivScholarshipEdit,
    CsvDownload,
    CsvUpload
  },
  data () {
    return {
      apiName: "gpath_career_univ_scholarship_list_console",
      cvModuleFile: "gpath_career_univ_scholarship",
      cvModulePrefix: "GCUS",
      cvCardTitle: "Scholarships",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GPath Career Univ Scholarship",
      cvAddModalHeader: "Add GPath Career Univ Scholarship",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPathCareerUnivScholarship",
      showModelGPathCareerUnivScholarshipAdd: false,
      showModelGPathCareerUnivScholarshipEdit: false,
      showModelGPathCareerUnivScholarshipDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "name", key: "name", class: "text-left align-text-top", sortable: true },
        { label: "description", key: "description", class: "text-left align-text-top", sortable: true },
        { label: "univ name", key: "univ_name", class: "text-left align-text-top", sortable: true },
        { label: "country", key: "country", class: "text-left align-text-top", sortable: true },
        { label: "url", key: "url", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      gpath_career_univ_scholarshipObjList: [],
      gpath_career_univ_scholarshipEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
      },
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    this.gpathCareerUnivScholarshipList()
  },
  methods: {
    /**
     * searchgpath_career_univ_scholarshipWithParams
     */
    async searchgpath_career_univ_scholarshipWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.gpathCareerUnivScholarshipList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.gpathCareerUnivScholarshipList(true)
    },
    /**
     * Calling Function as per whereFilter
    */
    /**
     * gpathCareerUnivScholarshipList
     */
    async gpathCareerUnivScholarshipList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param

        let gpathCareerUnivScholarshipListResp = await GPathCareerUnivScholarships.gpathCareerUnivScholarshipList(this, this.whereFilter)
        if (gpathCareerUnivScholarshipListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.gpath_career_univ_scholarshipObjList = [ ...gpathCareerUnivScholarshipListResp.resp_data.data ]
          }
          else {
            this.gpath_career_univ_scholarshipObjList = [ ...this.gpath_career_univ_scholarshipObjList, ...gpathCareerUnivScholarshipListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.gpath_career_univ_scholarshipObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.gpath_career_univ_scholarshipObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = gpathCareerUnivScholarshipListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpathCareerUnivScholarshipList() and Exception:", err)
      }
    },
    /**
     * gpath_career_univ_scholarshipAdd
     */
    gpath_career_univ_scholarshipAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/gpath_career_univ_scholarship_add")
        }
        else {
          this.showModelGPathCareerUnivScholarshipAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_univ_scholarshipAdd() and Exception:", err.message)
      }
    },
    /**
     * gpath_career_univ_scholarshipEdit
     */
    gpath_career_univ_scholarshipEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/gpath_career_univ_scholarship_edit/" + this.gpath_career_univ_scholarshipEditObj.gcus_id)
        }
        else {
          this.gpath_career_univ_scholarshipEditObj = item
          this.showModelGPathCareerUnivScholarshipEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_univ_scholarshipEdit() and Exception:", err.message)
      }
    },
    /**
     * showGPathCareerUnivScholarshipDeleteDialog
     */
    showGPathCareerUnivScholarshipDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGPathCareerUnivScholarshipDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGPathCareerUnivScholarshipDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * gpath_career_univ_scholarshipDelete
     */
    async gpath_career_univ_scholarshipDelete () {
      try {
        let gpathCareerUnivScholarshipDelResp = await GPathCareerUnivScholarships.gpath_career_univ_scholarshipDelete(this, this.delObj.gcus_id)
        await ApiResponse.responseMessageDisplay(this, gpathCareerUnivScholarshipDelResp)

        if (gpathCareerUnivScholarshipDelResp && !gpathCareerUnivScholarshipDelResp) {
          this.showModelGPathCareerUnivScholarshipDelete = false
          return false
        }

        let index = this.gpath_career_univ_scholarshipObjList.indexOf(this.delObj)
        this.gpath_career_univ_scholarshipObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGPathCareerUnivScholarshipDelete = false
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_univ_scholarshipDelete() and Exception:", err.message)
      }
    },
    /**
     * closeGPathCareerUnivScholarshipAddModal
     */
    closeGPathCareerUnivScholarshipAddModal (gpathCareerUnivScholarshipAddData) {
      try {
        if (gpathCareerUnivScholarshipAddData) {
          if (this.gpath_career_univ_scholarshipObjList && this.gpath_career_univ_scholarshipObjList.length >= 1) {
            let gpathCareerUnivScholarshipObjLength = this.gpath_career_univ_scholarshipObjList.length
            let lastId = this.gpath_career_univ_scholarshipObjList[gpathCareerUnivScholarshipObjLength - 1]["id"]
            gpathCareerUnivScholarshipAddData.id = lastId + 1
          }
          else {
            this.gpath_career_univ_scholarshipObjList = []
            gpathCareerUnivScholarshipAddData.id = 11111
          }

          gpathCareerUnivScholarshipAddData.created_on = moment()
          this.gpath_career_univ_scholarshipObjList.unshift(gpathCareerUnivScholarshipAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGPathCareerUnivScholarshipAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegpath_career_univ_scholarshipAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeGPathCareerUnivScholarshipEditModal
     */
    closeGPathCareerUnivScholarshipEditModal () {
      try {
        this.showModelGPathCareerUnivScholarshipEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGPathCareerUnivScholarshipEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
